import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import { styled, darken } from '@mui/material/styles';
import * as React from 'react';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import WidgetSearch from '../../Widget-Search/Widget-Search';
import { Box, Chip, Grid, IconButton, Popover, Stack, Theme, Typography, Badge, MenuItem, Menu } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { getAgentDrawerDeets, getAgentEvents, getAgentList, getAgentStateDetail, getWidgetConfig } from '../../../services/api-service';
import '../AgentList/AgentList.css';
import BargeInDrawer from '../../BargeInDrawer/BargeInDrawer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AgentDetailsDrawer from '../../AgentDetailsDrawer/AgentDetailsDrawer';
import { ReactComponent as DragIcon } from '../../../static/icons/drag.svg';
import { STATUS_CLASS_MAP, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import { AccessTime } from '@mui/icons-material';
import { checkRoles, mapRowDataWithThreshold, secondsToMinSec, secondsToTime, checkDataAccessFilters, determineFilterType } from '../../../utils/util';
import AgentListVTable from './AgentListVTable';
import SelectPopover from '../../SelectPopover/SelectPopover';
import { KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon } from '@mui/icons-material';

interface Column {
	id:
	| 'AgentName'
	| 'agentAction'
	| 'CampaignNameOfLastCall'
	| 'CallFrom_SkillName'
	| 'AgentMode'
	| 'CustomerPhoneNumber'
	| 'CallType'
	| 'hamburger'
	| 'Skill'
	| 'LastCall'
	| 'AgentState'
	| 'Since'
	| 'Details'
	| 'SkillsFormatted'
	| 'GroupsFormatted'
	| 'AgentId'
	| 'HoldDuration'
	| 'AgentPhoneNumber'
	| 'sip_location'
	| 'CallStatus';
	label: string;
	minWidth?: number;
	draggable?: boolean;
	width?: number;
	align?: 'right' | 'center' | 'left';
	type?: string;
}

const useStyles: any = makeStyles((theme: Theme) => ({
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2
	},
	dragdropItemsWrapper: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: '0 1rem',
		alignItems: 'center'
	},
	dropdownSearchContainer: {
		height: '30px',
		border: '1px solid #D6D6D7',
		borderRadius: '8px',
		backgroundColor: '#F2F2F2'
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#FFFFFF',
		height: '28px',
		borderLeft: '1px solid #D6D6D7',
		borderRadius: '0px 8px 8px 0px',
		width: '193px',
		padding: '0px 8px'
	},
	searchInput: {
		border: 'none',
		outline: 'none'
	},
	dropdownLabelMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important',
		width: '149px'
	},
	dropdownMenu: {
		minWidth: 'fit-content !important',
		border: '1px solid #e6e5e6 !important',
		boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important',
		borderRadius: '12px !important',
		marginTop: '10px !important'
	},
	dropdownMenuItem: {
		fontWeight: '400 !important',
		fontSize: '12px !important',
		lineHeight: '14px !important',
		color: '#212121 !important'
	},
}));

const DetailsIconButton = styled(IconButton)(
	({ theme }) => `
  background-color: ${theme.palette.primary.main};
	color: white;
	padding: 0.1rem;

  :hover {
    background-color: ${darken(theme.palette.primary.main, 0.2)};
  }
`
);

const thresholdItemsDefault = [
	// {
	// 	label: 'Idle',
	// 	key: 'AgentState',
	// 	value: 'IDLE',
	// 	fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	// },
	{
		label: 'Ready',
		key: 'AgentState',
		value: 'READY' || 'Ready',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	},
	{
		label: 'Busy',
		key: 'AgentState',
		value: 'BUSY' || 'Busy',
		fields: [
			{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' },
			{ label: `Hold Time(in sec's)`, key: 'HoldDuration', value: '', condition: 'GREATER_THAN' }
		]
	},
	{
		label: 'ACW',
		key: 'AgentState',
		value: 'ACW' || 'Acw',
		fields: [
			{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' },
			{ label: `Hold Time(in sec's)`, key: 'HoldDuration', value: '', condition: 'GREATER_THAN' }
		]
	},
	{
		label: 'Pause',
		key: 'AgentState',
		value: 'PAUSED' || 'Paused',
		fields: [
			{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' },
			{ label: `Hold Time(in sec's)`, key: 'HoldDuration', value: '', condition: 'GREATER_THAN' }
		]
	},
	// {
	// 	label: 'Calling',
	// 	key: 'AgentState',
	// 	value: 'CALLING' || 'Calling',
	// 	fields: [
	// 		{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' },
	// 		{ label: `Hold Time(in sec's)`, key: 'HoldDuration', value: '', condition: 'GREATER_THAN' }
	// 	]
	// },
	{
		label: 'AUX',
		key: 'AgentState',
		value: 'AUX' || 'Aux',
		fields: [{ label: `Duration(in sec's)`, key: 'Since', value: '', condition: 'GREATER_THAN' }]
	}
];
const defaultColumns: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120, width: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120, width: 120 },
	{ id: 'agentAction', label: '', minWidth: 30, width: 120 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
		width: 120
	},
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, width: 120, type: 'TIME' },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'sip_location', label: 'SIP Location', align: 'center', minWidth: 50, width: 120 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, align: 'center', minWidth: 30, width: 120 },
	{ id: 'SkillsFormatted', label: 'Skills', align: 'left', minWidth: 290, width: 120 },
	{ id: 'CallStatus', label: 'Last Call', align: 'left', minWidth: 50, width: 120 },
	{ id: 'AgentState', label: 'State', draggable: true, align: 'center', minWidth: 50, width: 120 },
	{ id: 'Since', label: 'Duration', align: 'center', minWidth: 30, width: 120, type: 'TIME' },
	{ id: 'Details', label: 'Details', align: 'center', minWidth: 30, width: 120 },

	// { id: 'holdTime', label: 'Hold Time', minWidth: 65 },
	// { id: 'AgentState', label: 'Status', minWidth: 50 },
	// { id: 'Since', label: 'Duration', minWidth: 50 },
	// { id: 'adminAction', label: 'Admin Action', minWidth: 185 },
	{ id: 'hamburger', label: '', minWidth: 20 },
];

const columnsWithDataAccess: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120, width: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120, width: 120 },
	{ id: 'agentAction', label: '', minWidth: 30, width: 120 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{ id: 'GroupsFormatted', label: 'Groups', minWidth: 50, align: 'left' },
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
		width: 120
	},
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, width: 120, type: 'TIME' },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'sip_location', label: 'SIP Location', align: 'center', minWidth: 50, width: 120 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, align: 'center', minWidth: 30, width: 120 },
	{ id: 'SkillsFormatted', label: 'Skills', align: 'left', minWidth: 290, width: 120 },
	{ id: 'CallStatus', label: 'Last Call', align: 'left', minWidth: 50, width: 120 },
	{ id: 'AgentState', label: 'State', draggable: true, align: 'center', minWidth: 50, width: 120 },
	{ id: 'Since', label: 'Duration', align: 'center', minWidth: 30, width: 120, type: 'TIME' },
	{ id: 'Details', label: 'Details', align: 'center', minWidth: 30, width: 120 },
	{ id: 'hamburger', label: '', minWidth: 20 }
];

const defaultDownloadColumns: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120, width: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120, width: 120 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
		width: 120
	},
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, width: 120, type: 'TIME' },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'sip_location', label: 'SIP Location', align: 'center', minWidth: 50, width: 120 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, align: 'center', minWidth: 30, width: 120 },
	{ id: 'SkillsFormatted', label: 'Skills', align: 'left', minWidth: 290, width: 120 },
	{ id: 'CallStatus', label: 'Last Call', align: 'left', minWidth: 50, width: 120 },
	{ id: 'AgentState', label: 'State', draggable: true, align: 'center', minWidth: 50, width: 120 },
	{ id: 'Since', label: 'Duration', align: 'center', minWidth: 30, width: 120, type: 'TIME' },
];

const downloadColumnsWithDataAccess: Column[] = [
	{ id: 'AgentId', label: 'Agent Id', minWidth: 120, width: 120 },
	{ id: 'AgentName', label: 'Agent Name', minWidth: 120, width: 120 },
	{
		id: 'CampaignNameOfLastCall',
		label: 'Campaign Name',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{
		id: 'CallFrom_SkillName',
		label: 'Call From Skill',
		draggable: true,
		minWidth: 120,
		width: 150
	},
	{ id: 'GroupsFormatted', label: 'Groups', minWidth: 50, align: 'left' },
	{
		id: 'CallType',
		label: 'Call Type',
		draggable: true,
		minWidth: 50,
		width: 120
	},
	{ id: 'CustomerPhoneNumber', label: 'Customer No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, width: 120, type: 'TIME' },
	{ id: 'AgentPhoneNumber', label: 'Agent Phone No.', align: 'center', minWidth: 50, width: 120 },
	{ id: 'sip_location', label: 'SIP Location', align: 'center', minWidth: 50, width: 120 },
	{ id: 'AgentMode', label: 'Mode', draggable: true, align: 'center', minWidth: 30, width: 120 },
	{ id: 'SkillsFormatted', label: 'Skills', align: 'left', minWidth: 290, width: 120 },
	{ id: 'CallStatus', label: 'Last Call', align: 'left', minWidth: 50, width: 120 },
	{ id: 'AgentState', label: 'State', draggable: true, align: 'center', minWidth: 50, width: 120 },
	{ id: 'Since', label: 'Duration', align: 'center', minWidth: 30, width: 120, type: 'TIME' },
];

let interval: any;

export default function AgentList(props: any) {
	const { useState, useRef } = React;
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columns, setColumns] = useState<any>(defaultColumns);
	const [downloadColumns, setDownloadColumns] = useState<Array<any>>(defaultDownloadColumns);
	const [filteredRowData, setFilteredRowData] = useState<Array<any>>([]);
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [isBargeInOpen, setIsBargeInOpen] = useState<boolean>(false);
	const [isAgentDetailsDrawerOpen, setIsAgentDetailsDrawerOpen] = useState<boolean>(false);
	const [eleRefPoint, setElRefPoint] = useState<any>(null);
	const [selectedAgent, setSelectedAgent] = useState<any>(null);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkDataAccessFilters() ? 3 : 1);
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [agentListFilter, setAgentListFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [groupingColumns, setGroupingColumns] = useState<any>([]);
	const [groupedData, setGroupedData] = useState<any>({});
	const downloadContent = useRef<null | any>(null);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const [selectedAgentEventDetails, setSelectedAgentEventDetails] = useState<any>(null);
	const [isEventsPopoverOpen, setIsEventsPopoverOpen] = useState<boolean>(false);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>(thresholdItemsDefault);
	const [drawerDetails, setDrawerDetails] = useState<any>({});
	const [searchText, setSearchText] = useState<any>({});
	const [searchTextForAgentID, setSearchTextForAgentID] = useState<any>({});
	const tableContainerRef = useRef(null);
	const tableDataRef = useRef(null);
	const [resized, setResized] = useState<any>(false)
	const [moreData, setMoreData] = useState<any>(false);
	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = useState<boolean>(false);
	const [columnsEleRefPoint, setColumnsElRefPoint] = useState<any>(null);
	const [searchFieldMenuAnchorEl, setSearchFieldMenuAnchorEl] = useState<any>(null);
	const labelOpen = Boolean(searchFieldMenuAnchorEl);
	const [searchSelect, setSearchSelect] = useState<any>({ name: 'Agent Name', key: 'AgentName'});
	const [selectedColumns, setSelectedColumns] = useState<any>(props?.selectedFilters?.filterHeaders || columns);

	React.useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			if (entries && entries.length > 0) {
				const newHeight: any = entries[0].target.clientHeight;
				if (parseInt(newHeight) > 488) {
					setResized(true)
				}
				if (parseInt(newHeight) === 408) {
					setResized(false)
				}
				if (parseInt(newHeight) === 618) {
					setResized(false)
				}
			}
		});

		const resizeDataObserver = new ResizeObserver((entries) => {
			if (entries && entries.length > 0) {
				const Height: any = entries[0].target.clientHeight;
				if (parseInt(Height) > 280) {
					setMoreData(true)
				}
			}
		});

		if (tableContainerRef.current) {
			resizeObserver.observe(tableContainerRef.current);
		};

		if (tableDataRef.current) {
			resizeDataObserver.observe(tableDataRef.current);
		};

		return () => {
			resizeObserver.disconnect();
			resizeDataObserver.disconnect()
		};
	}, []);

	React.useEffect(() => {
		if (checkRoles("ROLE_DATA_ACCESS")) {
			setColumns(columnsWithDataAccess);
			setSelectedColumns(columnsWithDataAccess);
			setDownloadColumns(downloadColumnsWithDataAccess);
		} else {
			setColumns(defaultColumns);
			setSelectedColumns(defaultColumns);
			setDownloadColumns(defaultDownloadColumns);
		}

	}, []);

	React.useEffect(() => {
		const fetchWidgetConfig = async () => {
			try {
				if (checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('104', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
							? widgetConfig.data
							: widgetConfig.config;

						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: '#F0F1F2',
			color: '#000000',
			padding: '10px 16px'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			padding: '10px 16px'
		}
	}));
	const agentDetailsItems = [
		{ label: 'Total Login', key: 'TotalLoginDuration', type: true },
		{ label: 'Last Logout', key: 'LastLogoutTime' },
		{ label: 'Calls Offered', key: 'TotalCalls' },
		{ label: 'Answered', key: 'AnsweredCalls' },
		{ label: 'Abandoned', key: 'AbandonedCalls' },
		{ label: 'Average Hold Time', key: 'AverageHoldDuration' },
		{ label: 'AHT', key: 'AverageHandlingDuration' },
		{ label: 'ASA', key: 'AverageSpeedToAnswer' },
		{ label: 'ACW', key: 'TotalWrapupDuration' },
		{ label: 'Pause Time', key: 'TotalPauseDuration' }
	];
	const getPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div>
					{agentDetailsItems.map((item: any, idx: number) => (
						<Grid key={idx} container direction="row" justifyContent="space-between" alignItems="center">
							<Grid item xs={7}>
								<b>{item.label}</b>
							</Grid>

							{item.type ? <span>{secondsToTime(selectedAgent?.[item.key]) || 0}</span> : <span>{selectedAgent?.[item.key] || 0}</span>}
						</Grid>
					))}
				</div>
			</Box>
		);
	};

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	const handleOnSelectColumnChange = (selected: any) => {

		let selectedIds: string[] = []
		let orderedSelected = [];

		selected.map((selected: any) => {
			selectedIds.push(selected.id)
		})

		if (selectedIds.includes("AgentId")) {
			orderedSelected.push({ id: 'AgentId', label: 'Agent Id', minWidth: 120 })
		}
		if (selectedIds.includes("AgentName")) {
			orderedSelected.push({ id: 'AgentName', label: 'Agent Name', minWidth: 80 })
		}
		if (selectedIds.includes("agentAction")) {
			orderedSelected.push({ id: 'agentAction', label: '', minWidth: 30 })
		}
		if (selectedIds.includes("CampaignNameOfLastCall")) {
			orderedSelected.push({ id: 'CampaignNameOfLastCall', label: 'Campaign Name', draggable: true })
		}
		if (selectedIds.includes("CallFrom_SkillName")) {
			orderedSelected.push({ id: 'CallFrom_SkillName', label: 'Call From Skill', draggable: true })
		}
		if (selectedIds.includes("CallType")) {
			orderedSelected.push({ id: 'CallType', label: 'Call Type', draggable: true, minWidth: 50 })
		}
		if (selectedIds.includes("AgentPhoneNumber")) {
			orderedSelected.push({ id: 'AgentPhoneNumber', label: 'Agent Phone No.', minWidth: 50 })
		}
		if (selectedIds.includes("sip_location")) {
			orderedSelected.push({ id: 'sip_location', label: 'SIP Location', minWidth: 50 })
		}
		if (selectedIds.includes("SkillsFormatted")) {
			orderedSelected.push({ id: 'SkillsFormatted', label: 'Skills', minWidth: 50 })
		}
		if (selectedIds.includes("GroupsFormatted")) {
			orderedSelected.push({ id: 'GroupsFormatted', label: 'Groups', minWidth: 50 })
		}
		if (selectedIds.includes("CustomerPhoneNumber")) {
			orderedSelected.push({ id: 'CustomerPhoneNumber', label: 'Customer No.', minWidth: 50 })
		}
		if (selectedIds.includes("AgentMode")) {
			orderedSelected.push({ id: 'AgentMode', label: 'Mode', draggable: true, minWidth: 30 })
		}
		if (selectedIds.includes("HoldDuration")) {
			orderedSelected.push({ id: 'HoldDuration', label: 'Hold Time', minWidth: 65, type: 'TIME' })
		}
		if (selectedIds.includes("AgentState")) {
			orderedSelected.push({ id: 'AgentState', label: 'State', draggable: true, minWidth: 50 })
		}
		if (selectedIds.includes("CallStatus")) {
			orderedSelected.push({ id: 'CallStatus', label: 'Last Call Status', minWidth: 50 })
		}
		if (selectedIds.includes("Since")) {
			orderedSelected.push({ id: 'Since', label: 'Duration', minWidth: 50, type: 'TIME' })
		}
		if (selectedIds.includes("adminAction")) {
			orderedSelected.push({ id: 'adminAction', label: 'Admin Action', minWidth: 185 })
		}
		if (selectedIds.includes("hamburger")) {
			orderedSelected.push({ id: 'hamburger', label: '', minWidth: 20 })
		}
		props?.handleFilterChange?.(agentListFilter, filterSection, props?.widgetId, orderedSelected);
		setSelectedColumns(orderedSelected);
		handleOnColumnsPopoverClose();
	};

	const onHamburgerClick = (event: any) => {
		setColumnsElRefPoint({ top: event.clientY, left: event.clientX });
		setIsColumnsPopoverOpen(true);
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(even)': {
			backgroundColor: '#edf0f280'
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0
		}
	}));
	const handleOnPopoverClose = () => {
		setElRefPoint(null);
		setSelectedAgent(null);
		setIsPopoverOpen(false);
	};
	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const getTableData = async (filters = '0') => {
		console.log("here all values are", filters);
		let data = await getAgentList({ filters, type: filterType });
		const updatedRowData = mapRowDataWithThreshold(thresholdItems, data);
		for (const row of updatedRowData) {
			const skills = row.AgentSkills?.split(',') || [];
			const groups = row.AgentGroups?.split(',') || [];
			const tempSkills = [...skills];
			const tempGroups = [...groups];
			if (groups.length > 3) {
				row.GroupsFormatted = (
					<div>
						{groups.slice(0, 3).join(',')}
						<span key="badge" className="badge" title={tempGroups.slice(3).join(',')}>
							{`+${groups.length - 3}`}
						</span>
					</div>
				);
			}
			else {
				row.GroupsFormatted = groups.join(',');
			}
			if (skills.length > 3) {
				row.SkillsFormatted = (
					<div>
						{skills.slice(0, 3).join(',')}
						<span key="badge" className="badge" title={tempSkills.slice(3).join(',')}>
							{`+${skills.length - 3}`}
						</span>
					</div>
				);
			} else {
				row.SkillsFormatted = skills.join(',');
			}
			if (row && row.PauseReason) {
				row.AgentState = row.PauseReason
			}
			if (checkRoles('ROLE_CHAT_CALL') && row && row?.SessionCount && parseInt(row.SessionCount) > 0) {
				row.AgentState = "BUSY";
			}
		}
		setRowData(updatedRowData);
		let filteredRows : any = [];
		if (searchText.length) {
			if(searchSelect.key === "AgentId"){
				filteredRows = updatedRowData.filter((item: any) => item.AgentId.toLowerCase().toString().includes(searchText.toString()));
			}else{
				filteredRows = updatedRowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			}
			// const filteredRows = updatedRowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(updatedRowData);
		}
	};

	const agentDetails = async (event: any, agent: any) => {
		const agentData = await getAgentStateDetail({ agentId: agent.AgentUniqueId });
		setSelectedAgent(agentData.find((eachAgent) => eachAgent));
		setElRefPoint({ top: event.clientY, left: event.clientX });
		setIsPopoverOpen(true);
	};

	// const agentStates = (agent: any) => {
	// 	console.log(agent);
	// };

	const agentEvents = async (event: any, agent: any) => {
		setSelectedAgent(agent);
		let response = await getAgentEvents({ agentId: agent.AgentUniqueId });
		response = response.map((dataItem) => ({
			...dataItem,
			StartTime: dataItem.StartTime ? `${dataItem.StartTime.toString().split(' ')[0]}T${dataItem.StartTime.toString().split(' ')[1]}` : '',
			EndTime: dataItem.EndTime ? `${dataItem.EndTime.split(' ')[0]}T${dataItem.EndTime.toString().split(' ')[1]}` : ''
		}));
		setSelectedAgentEventDetails(response);
		setElRefPoint({ top: event.clientY, left: event.clientX });
		setIsEventsPopoverOpen(true);
	};

	const toggleBargeInDrawer = () => {
		setIsBargeInOpen(!isBargeInOpen);
	};

	const agentDetailsDrawer = async (agent: any) => {
		const data = await getAgentDrawerDeets({ filters: '0', type: filterType, agentId: agent.AgentUniqueId });
		setDrawerDetails(data);
		setSelectedAgent(agent);
		toggleAgentDetailsDrawer();
	};

	const toggleAgentDetailsDrawer = () => {
		setIsAgentDetailsDrawerOpen(!isAgentDetailsDrawerOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = agentListFilter.filter((item: any) => item.id !== id);
		setAgentListFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId, selectedColumns);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setAgentListFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId, selectedColumns);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setAgentListFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId, selectedColumns);
	};

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>, item: any) => {
		event.dataTransfer?.setData('agentData', JSON.stringify(item));
	};

	const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
		const agentData: any = JSON.parse(event.dataTransfer?.getData('agentData') || '{}');
		if (!groupingColumns.find((col: any) => col.id === agentData.id)) {
			setGroupingColumns([...groupingColumns, agentData]);
		}
		return;
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		console.log("here full ata ", selectedFilters);
		setAgentListFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId, selectedColumns);
		toggleDrawer(false);
	};

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = agentListFilter.length ? agentListFilter.map((item: any) => item.id).join(',') : '0';
		getTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}

		interval = setInterval(() => getTableData(filterIds), WIDGET_REFRESH_INTERVAL.AGENT_LIST);
	}, [agentListFilter, thresholdItems, searchText]);

	// const groupColumns = (rowData: any, column: any) => {
	// 	return rowData.reduce((acc: any, value: any) => {
	// 		(acc[value[column.id]] = acc[value[column.id]] || []).push(value);
	// 		return acc;
	// 	}, {});
	// };

	React.useEffect(() => {
		if (groupingColumns.length && filteredRowData.length) {
			let groupedData = {};
			for (const data of filteredRowData) {
				// groupedData = groupColumns(rowData, data);

				groupingColumns
					.reduce((groupAcc: any, column: any, groupIdx: number) => {
						const key = data[column.id];
						groupAcc[key] = groupAcc[key] || (groupIdx === groupingColumns.length - 1 ? [] : {});
						return groupAcc[key];
					}, groupedData)
					.push(data);
			}
			setGroupedData(groupedData);
		}
	}, [groupingColumns, filteredRowData]);

	const filterItems = agentListFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const handleOnEventsPopoverClose = () => {
		setElRefPoint(null);
		setSelectedAgent(null);
		setIsEventsPopoverOpen(false);
	};

	const formatAMPM = (date: Date) => {
		if (date) {
			var hours = date.getHours();
			var minutes: any = date.getMinutes();
			var ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime = hours + ':' + minutes + ' ' + ampm;
			return strTime;
		} else {
			return '';
		}
	};

	const getTimeDifferenceInString = (higherDate: string, lowerDate: string) => {
		const seconds = (new Date(higherDate).getTime() - new Date(lowerDate).getTime()) / 1000;
		if (!isNaN(seconds)) {
			if (seconds > 60) {
				const minutes = seconds / 60;
				if (minutes > 60) {
					const hours = minutes / 60;
					return `${hours.toFixed(1)} Hrs`;
				} else {
					return `${Math.floor(minutes)} Mins`;
				}
			} else {
				return `${seconds} Secs`;
			}
		} else {
			return '';
		}
	};

	const getColorByAgentEvent = (event: string) => {
		return event.toLowerCase() === 'calling'
			? 'yellow'
			: event.toLowerCase() === 'aux'
				? 'green'
				: event.toLowerCase() === 'release'
					? 'red'
					: event.toLowerCase() === 'idle'
						? 'orange'
						: event.toLowerCase() === 'incall'
							? 'lightgreen'
							: '';
	};

	const getEventsPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div>
					<h3>{selectedAgent?.AgentName}</h3>
					<Stack direction={'column'} gap={1}>
						{selectedAgentEventDetails?.map((item: any, idx: number) => (
							<Box key={idx} display={'flex'} justifyContent="space-between" alignItems="center">
								<Box display={'flex'} gap={1}>
									{
										<Box
											sx={{
												background: getColorByAgentEvent(item.Event),
												width: '2px',
												height: '2rem',
												borderRadius: '4px'
											}}
										></Box>
									}
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Stack direction={'row'} gap={5}>
											<Typography
												sx={{
													minWidth: '5rem',
													fontWeight: '500',
													fontSize: '12px',
													color: 'rgba(33, 33, 33, 0.7)'
												}}
											>
												{item.Event.toUpperCase()}
											</Typography>

											{item?.StartTime && item?.EndTime && (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														gap: '5px',
														fontWeight: '400',
														fontSize: '12px',
														color: '#99A0A8'
													}}
												>
													<AccessTime sx={{ color: 'inherit', fontSize: '18px' }} />

													<Typography sx={{ color: 'inherit', fontSize: 'inherit' }}>{getTimeDifferenceInString(item?.EndTime, item?.StartTime)}</Typography>
												</Box>
											)}
										</Stack>
										<Typography
											sx={{
												fontWeight: '400',
												fontSize: '12px',
												color: '#99A0A8'
											}}
										>
											{formatAMPM(new Date(item?.StartTime))}
										</Typography>
									</Box>
								</Box>
							</Box>
						))}
					</Stack>
				</div>
			</Box>
		);
	};

	const getTableRows = (data: any) => {
		return (
			<>
				{data.map((row: any, rowIdx: number) => {
					return (
						<StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}>
							{groupingColumns.map((groupName: any, idx: number) => {
								return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
							})}
							{selectedColumns.map((column: any) => {
								const value: any = row[column.id];
								if (column.id === 'agentAction') {
									return (
										<StyledTableCell key={column.id + rowIdx} align={column.align}>
											<span className="icon-row">
												<span className="icon-col-sm">
													<IconButton disableRipple onClick={(event) => agentDetails(event, row)}>
														<img src={`${process.env.PUBLIC_URL}/icons/table-info-icon.svg`} width="20" alt="Info"></img>
													</IconButton>
												</span>
												<span className="icon-col-sm">
													<IconButton disableRipple onClick={(event) => agentEvents(event, row)}>
														<img src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`} width="20" alt="Graph"></img>
													</IconButton>
												</span>
											</span>
										</StyledTableCell>
									);
								} else if (column.id === 'AgentState') {
									return (
										<StyledTableCell key={column.id + rowIdx} align={'center'} width={column.width}>
											{row?.PauseReason ?
												<>
													<span className={STATUS_CLASS_MAP['PAUSED']}>{value}</span>
												</>
												:
												<>
													<span className={STATUS_CLASS_MAP[value]}>{value}</span>
												</>
											}
										</StyledTableCell>
									);
								} else if (column.id === 'Details') {
									return (
										<StyledTableCell key={column.id + rowIdx} align={'center'} width={column.width}>
											<DetailsIconButton color="primary" onClick={() => agentDetailsDrawer(row)}>
												<ArrowRightAltIcon sx={{ fontSize: '20px' }} />
											</DetailsIconButton>
										</StyledTableCell>
									);
								}
								// else if (column.id === 'Since') {
								// 	return (
								// 		<StyledTableCell
								// 			key={column.id + rowIdx}
								// 			align={'center'}
								// 			style={
								// 				{
								// 					// backgroundColor: minutes > 6 ? '#F2C2C2' : 'unset'
								// 				}
								// 			}
								// 			width={column.width}
								// 		>
								// 			{value}
								// 		</StyledTableCell>
								// 	);
								// }
								else {
									return (
										<StyledTableCell
											key={column.id + rowIdx}
											align={column.align}
											width={column.width}
											sx={{
												maxWidth: column.width
											}}
											className={`${row[column.id]?.warning || ''}`}
										>
											{(column.type === 'MIN_SEC' && secondsToMinSec(row[column.id]?.value || row[column.id])) ||
												(column.type === 'TIME' && secondsToTime(row[column.id]?.value || row[column.id])) ||
												value}
										</StyledTableCell>
									);
								}
							})}
						</StyledTableRow>
					);
				})}
			</>
		);
	};

	// const getRandomColor = () => {
	// 	// return '#' + Math.floor(Math.random() * 16777215).toString(16);
	// 	return '#' + (((1 << 24) * Math.random()) | 0).toString(16);
	// };

	function getColor(numOfSteps: number = 1, step: number = 0) {
		// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
		// Adam Cole, 2011-Sept-14
		// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
		var r = 0,
			g = 0,
			b = 0;
		var h = step / numOfSteps;
		var i = ~~(h * 6);
		var f = h * 6 - i;
		var q = 1 - f;
		switch (i % 6) {
			case 0:
				r = 1;
				g = f;
				b = 0;
				break;
			case 1:
				r = q;
				g = 1;
				b = 0;
				break;
			case 2:
				r = 0;
				g = 1;
				b = f;
				break;
			case 3:
				r = 0;
				g = q;
				b = 1;
				break;
			case 4:
				r = f;
				g = 0;
				b = 1;
				break;
			case 5:
				r = 1;
				g = 0;
				b = q;
				break;
		}
		var c = '#' + ('00' + (~~(r * 255)).toString(16)).slice(-2) + ('00' + (~~(g * 255)).toString(16)).slice(-2) + ('00' + (~~(b * 255)).toString(16)).slice(-2);
		return c;
	}

	const getRows: any = (data: any, index: number) => {
		if (!Array.isArray(data)) {
			return Object.entries(data).map(([groupName, groupRowData]) => {
				const extraCells = [];

				for (let i = 0; i < index; i++) {
					extraCells.push(<TableCell key={i}></TableCell>);
				}

				return (
					<>
						<TableRow
							className="rowsGroupWrapper"
							sx={{
								// border: '2px solid #badaf2',
								// display: 'inline-block',
								width: '100%',
								height: '22px',
								backgroundColor: getColor(groupingColumns.length, index) + '4d'
								// minHeight: '1rem',
								// paddingTop: '1rem',
							}}
						>
							{extraCells}
							<TableCell colSpan={columns.length + groupingColumns.length} sx={{ padding: '5px 16px', background: 'transparent' }}>
								{groupName}
							</TableCell>
						</TableRow>
						{getRows(groupRowData, index + 1)}
					</>
				);
			});
		} else {
			return <TableBody>{getTableRows(data)}</TableBody>;
		}
	};

	const getGroupedRows = () => {
		if (groupingColumns.length) {
			return getRows(groupedData, 0);
		} else {
			return <TableBody>{getTableRows(filteredRowData)}</TableBody>;
		}
	};

	const handleOnSearch = (event: any) => {
		setSearchText(event.target.value.length ? event.target.value.toLowerCase() : '');
		// setSearchTextForAgentID(event.target.value.length ? event.target.value : '');
		if (event?.target?.value?.length) {
			const searchText = event.target.value.toLowerCase();
			let filteredRows : any = [];
			if(searchSelect.key === "AgentId"){
				filteredRows = rowData.filter((item: any) => item.AgentId.toLowerCase().toString().includes(searchText.toString()));
			}else{
				filteredRows = rowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			}
			// const filteredRows = rowData.filter((item: any) => item.AgentName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(rowData);
		}
	};
	const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			setThresholdItems(data);
		}
	};

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				SkillsFormatted: item?.AgentSkills ? item?.AgentSkills : item?.AgentSkills,
				GroupsFormatted: item?.AgentGroups ? item?.AgentGroups : item?.AgentGroups,
				Since: secondsToTime(item?.Since),
				HoldDuration: secondsToTime(item?.HoldDuration),
				// CampaignNameOfLastCall: item?.CampaignNameOfLastCall ? item?.CampaignNameOfLastCall : "",
			};
			return rowObj;
		});
		return row;
	}


	const AgentListHeaderContent = () => {
		return (
			<TableRow>
				{groupingColumns.map((groupName: any, idx: number) => {
					return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
				})}
				{selectedColumns.map((column: any, idx: any) => {
					if (column.draggable) {
						return (
							<StyledTableCell
								key={column.id + idx}
								className="draggable-table-cell"
								align={'left'}
								draggable={true}
								sx={{
									minWidth: column.minWidth,
									whiteSpace: 'nowrap',
									// maxWidth: column.width
								}}
								onDragStart={(event) =>
									handleDragStart(event, {
										label: column.label,
										id: column.id
									})
								}
							>
								<Typography display={'flex'} alignItems={'center'}>
									<span style={{ padding: '0 0.2rem' }}>
										<DragIcon />
									</span>
									{column.label}
								</Typography>
							</StyledTableCell>
						);
					}else if (column.id === 'hamburger') {
						return (
							<StyledTableCell
								key={column.id + idx}
								style={{
									minWidth: column.minWidth,
									textAlign: 'center'
								}}
							>
								<IconButton onClick={onHamburgerClick}>
									<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
								</IconButton>
							</StyledTableCell>
						);
					} else {
						return (
							<StyledTableCell
								key={column.id + idx}
								align={column.align}
								style={{
									minWidth: column.minWidth,
									whiteSpace: 'nowrap',
									// maxWidth: column.width
								}}
							>
								{column.label}
							</StyledTableCell>
						);
					}
				})}
			</TableRow>
		)
	}

	let finalTableData: any = []
	const getAgentListTableData = () => {
		if (groupingColumns.length) {
			finalTableData = []
			getFinalRows(groupedData, 0);
			return finalTableData;
		} else {
			return filteredRowData
		}
	}

	const getFinalRows: any = (data: any, index: number) => {
		if (!Array.isArray(data)) {
			return Object.entries(data).map(([groupName, groupRowData]) => {
				const extraCell: any = {}
				for (let i = 0; i < index; i++) {

					extraCell['extraCell' + i] = ""

				}
				extraCell['index'] = index
				extraCell['groupName'] = groupName;
				finalTableData.push(extraCell);
				getFinalRows(groupRowData, index + 1)
			})

		} else {
			finalTableData = finalTableData.concat(data);
		}
	}

	function rowContent(_index: number, row: any) {
		let rowIdx = _index
		if (row.hasOwnProperty('groupName')) {
		}

		return (
			(row.hasOwnProperty('groupName')) ?
				<React.Fragment>
					{Object.keys(row).map((key, i) => {
						let colorr = getColor(groupingColumns.length, _index) + '4d';
						if (key === 'groupName') {
							return <TableCell colSpan={selectedColumns.length + groupingColumns.length} sx={{ padding: '5px 16px', background: colorr }}>
								{row.groupName}
							</TableCell>
						} if (key.includes('extraCell')) {
							return <StyledTableCell key={key} sx={{ background: colorr }}>
								{''}
							</StyledTableCell>
						}
					})}
				</React.Fragment >
				:

				<React.Fragment>
					{/* <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.SNO}> */}
					{groupingColumns.map((groupName: any, idx: number) => {
						return <StyledTableCell key={groupName + idx} align="left"></StyledTableCell>;
					})}
					{selectedColumns.map((column: any) => {
						const value: any = row[column.id];
						if (column.id === 'agentAction') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={column.align}>
									<span className="icon-row">
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentDetails(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-info-icon.svg`} width="20" alt="Info"></img>
											</IconButton>
										</span>
										<span className="icon-col-sm">
											<IconButton disableRipple onClick={(event) => agentEvents(event, row)}>
												<img src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`} width="20" alt="Graph"></img>
											</IconButton>
										</span>
									</span>
								</StyledTableCell>
							);
						} else if (column.id === 'AgentState') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'} width={column.width}>
									{row?.PauseReason ?
										<>
											<span className={STATUS_CLASS_MAP['PAUSED']}>{value}</span>
										</>
										:
										<>
											<span className={STATUS_CLASS_MAP[value]}>{value}</span>
										</>
									}
								</StyledTableCell>
							);
						} else if (column.id === 'Details') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'} width={column.width}>
									<DetailsIconButton color="primary" onClick={() => agentDetailsDrawer(row)}>
										<ArrowRightAltIcon sx={{ fontSize: '20px' }} />
									</DetailsIconButton>
								</StyledTableCell>
							);
						} else if (column.id === 'hamburger') {
							return (
								<StyledTableCell key={column.id + rowIdx} align={'center'}>
									{value === 'expand' && (
										<span className="icon-row">
											<span className="icon-col">
												<img src={`${process.env.PUBLIC_URL}/icons/table-expand-icon.svg`} width="10" alt="Expand"></img>
											</span>
										</span>
									)}
								</StyledTableCell>
							);
						}
						// else if (column.id === 'Since') {
						// 	return (
						// 		<StyledTableCell
						// 			key={column.id + rowIdx}
						// 			align={'center'}
						// 			style={
						// 				{
						// 					// backgroundColor: minutes > 6 ? '#F2C2C2' : 'unset'
						// 				}
						// 			}
						// 			width={column.width}
						// 		>
						// 			{value}
						// 		</StyledTableCell>
						// 	);
						// }
						else {
							return (
								<StyledTableCell
									key={column.id + rowIdx}
									align={column.align}
									width={column.width}
									sx={{
										minWidth: column.minWidth,
										whiteSpace: 'nowrap',
										// maxWidth: column.width
									}}
									className={`${row[column.id]?.warning || ''}`}
								>
									{(column.type === 'MIN_SEC' && secondsToMinSec(row[column.id]?.value || row[column.id])) ||
										(column.type === 'TIME' && secondsToTime(row[column.id]?.value || row[column.id])) ||
										value}
								</StyledTableCell>
							);
						}
					})}
					{/* </StyledTableRow> */}
				</React.Fragment>
		);
	}


	const getTableStyle = () => {
		let height = 54;
		if (agentListFilter.length > 0) {
			height += 14;
		}
		return `calc(100% - ${height}px)`;
	}

	const handleSelectSearchField = (type: any = null) => {
		if(type){
			setSearchSelect(type);
		}
		setSearchFieldMenuAnchorEl(null);
	};

	const handleSearchFieldMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setSearchFieldMenuAnchorEl(event.currentTarget);
	};

	const searchFields: any = [
		{ name: 'Agent Id', key: 'AgentId' },
		{ name: 'Agent Name', key: 'AgentName'},
	];

	return (
		<Card className={!moreData ? "changedCustomCard" : resized ? "changedCustomCard" : "customCard"} ref={tableContainerRef}>
			<div className="card-header">
				<div className="card-title">
					Agent List V2 <div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<Grid container style={{border: '1px solid #D6D6D7',borderRadius: '8px 0px 0px 8px',backgroundColor: '#F2F2F2', width: '125px', marginRight: '18rem'}}>
						<Grid item sx={{display: 'flex'}}>
							<div style={{flexGrow: 1, minWidth: '100px'}} onClick={handleSearchFieldMenuOpen}>
								<Typography
									variant="caption"
									sx={{
										fontWeight: 400,
										fontSize: '12px',
										textAlign: 'right',
										color: '#212121',
										cursor: 'pointer',
										paddingLeft: '15px',
									}}
								>
									{searchSelect.name}
								</Typography>
							</div>
							<Menu
								sx={{ width: '50px' }}
								anchorEl={searchFieldMenuAnchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={labelOpen}
								onClose={() => handleSelectSearchField()}
								PaperProps={{ className: classes.dropdownMenu }}
							>
								{searchFields?.map((eachField: any, idx: any) => {
									return (
										<MenuItem key={idx} className={classes.dropdownLabelMenuItem} onClick={() => handleSelectSearchField(eachField)}>
											{eachField.name}
										</MenuItem>
									);
								})}
							</Menu>
							<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px', marginRight: '13px' , marginTop: 10}} />
							<WidgetSearch widget onTextChange={handleOnSearch}></WidgetSearch>
						</Grid>
					</Grid>
					{/* <WidgetSearch onTextChange={handleOnSearch}></WidgetSearch> */}
					<WidgetDownload element={tableDataRef} xlsx rowData={getRowData(rowData)} columns={selectedColumns} name={'Agent List V2'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={agentListFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
					<div onClick={() => toggleThresholdDrawer(true, null)}>
						<WidgetGraph></WidgetGraph>
					</div>
				</div>
			</div>
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentListFilter} setResponseLength={setresponseLength} allowedFilters={allowedFilters} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentListFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
			<Box sx={{ pt: 2, pl: 2 }}>
				{agentListFilter.length > 0 && (
					<div className="filter-outer">
						<div className="filter-container">
							<Stack direction="row" spacing={1}>
								{filterItems}
							</Stack>
						</div>
						<div className="filter-remove" onClick={clearFilter}>
							Remove
						</div>
					</div>
				)}
			</Box>
			<div className="search-drag-drop" onDrop={handleOnDrop} onDragOver={(e) => e.preventDefault()}>
				{groupingColumns.length > 0 && (
					<Box className={classes.dragdropItemsWrapper}>
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{groupingColumns.map((item: any, index: number) => (
										<Chip
											key={index}
											label={item.label}
											color="primary"
											variant="outlined"
											onDelete={() => {
												setGroupingColumns((prev: any) => prev.filter((_: any) => _.id !== item.id));
											}}
											deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />}
										/>
									))}
								</Stack>
							</div>
							<div className="filter-remove" onClick={() => setGroupingColumns([])}>
								Remove
							</div>
						</div>
					</Box>
				)}
				<Typography
					component={'span'}
					color="#99A0A8"
					position="absolute"
					// top={'50'}
					// left={'50'}
					fontSize={'12px'}
				>
					{groupingColumns.length < 3 && 'Drag and drop the metrics for grouping '}
					{/* <span>
						(Currently you don’t have any metrics which can be grouped)
					</span> */}
				</Typography>
			</div>
			{/* <Paper sx={{ width: '100%', overflow: 'hidden' }} className='custom-table-paper-height'> */}
			<Paper sx={{
				width: '100%', overflow: 'hidden',
				height: getTableStyle(),
			}}>
				{/* <TableContainer className="custom-table-scroll custom-table-maxHeight" ref={downloadContent} > */}
				{/* <Table stickyHeader aria-label="sticky table"> */}
				<AgentListVTable
					tableDataRef={tableDataRef}
					HeaderContent={AgentListHeaderContent}
					rowContent={rowContent}
					data={getAgentListTableData()}
				/>
			</Paper>
			<Popover
				open={isPopoverOpen}
				onClose={handleOnPopoverClose}
				anchorReference="anchorPosition"
				anchorPosition={eleRefPoint}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
			>
				{getPopoverContent()}
			</Popover>
			<Popover
				open={isEventsPopoverOpen}
				onClose={handleOnEventsPopoverClose}
				anchorReference="anchorPosition"
				anchorPosition={eleRefPoint}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
			>
				{getEventsPopoverContent()}
			</Popover>
			<BargeInDrawer isOpen={isBargeInOpen} toggleDrawer={toggleBargeInDrawer} agentDetails={selectedAgent} />
			<AgentDetailsDrawer isOpen={isAgentDetailsDrawerOpen} toggleDrawer={toggleAgentDetailsDrawer} selectedAgent={selectedAgent} agentDetails={drawerDetails} />
			<ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Set'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
			<SelectPopover
				isOpen={isColumnsPopoverOpen}
				elementsArray={columns}
				anchorRef={columnsEleRefPoint}
				handlePopoverClose={handleOnColumnsPopoverClose}
				selectedElements={selectedColumns}
				handleOnSelectionChange={handleOnSelectColumnChange}
			/>
		</Card>
	);
}
