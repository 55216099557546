import './admin.css'
import { Route, Routes } from "react-router-dom";
import Agent from "./agent/Agent";
import Disposition from './Dispositions/Dispositions';
import IvrFlow from './IVR_Flow/Ivr';
import PauseReasons from './PauseReasons/PauseReasons';
import Skill from './Skills/Skill';
import Integration from './Integration/Integration';
import AgentSettings from './AgentSettings/Agentsetting';
import Mappings from './Mappins/Mappings';
import Locations from './Locations/Locations';
import Campaigns from './Campaigns/Campaigns';
import { Templates } from './EmailTemplate/EmailTemplate';
import AdminSettings from './AdminSettings/Adminsettings';
import Groups from './Groups/Groups';
import ManageNumbers from './ManageNumbers/ManageNumbers';
import DNCRegulations from './DNCRegualtions/DNCRegulations';
import Users from './Users/Users';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { setCloseSnackBarRef, setSnackBarRef } from '../../components/admin/common/NotiService';
import IntegrationsHome from './Integration/IntegrationsHome';
import { checkRoles } from '../../utils/util';
import DIDAddition from './DID_Addition/DID_Addition';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../services/page-service';
import SIPLocation from './SIPLocations/SIPLocation';
import WidgetDetails from '../adminstration/WidgetDetails';
import AddCallWork from './AddCallWork/AddCallWork';
import MusicFile from './MusicFile/MusicFile';
import SipNumber from './SipNumber/SipNumber';
import Holidays from './Holidays/Holidays';
import BulkUpdate from './BulkUpdateSettings/BulkUpdate';
import SubUsers from './SubUsers/SubUsers';
import Plans from './Plans/Plans';
import { getUserModules } from '../../services/api-service';
import SubUserProfile from './Sub_UsersProfile/SubUserProfile';
import CXIReachContent from './CXIReach/CXIReachContent';
import SenderManagement from './CXIReach/SenderManagement';
import BATemplates from './CXIReach/BATemplates';
import RateCards from './CXIReach/RateCards';
import RateTrans from './CXIReach/RateTrans';
import Features from '../adminstration/Feature/Feature'; 

function Admin() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        setSnackBarRef(enqueueSnackbar);
        setCloseSnackBarRef(closeSnackbar);
    }, [enqueueSnackbar, closeSnackbar])

    const [superAdmin, setSuperAdmin]= useState(false);
    useEffect(()=>{
        getUserModules().then((resp: any) => {
            let roles = resp.Data.roles;
            let isFound = false;
            if (roles != null && roles.length !== 0) {
                roles.forEach((role: any) => {
                    if (role.name === 'ROLE_ADMIN') {
                        isFound = true;
                        return
                    }
                });
            }
            setSuperAdmin(isFound);
        })
    },[])

    return (
        <div id="adminModule">
            <Routes>
                {(newAuthPages(NewAdminPages.DISPOSITIONS_PAGE) || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.DISPOSITIONS_PAGE, 'View'))
                    && < Route path={`/dispositions`} element={<Disposition />} />}
                {(newAuthPages(NewAdminPages.IVR_FLOWS) || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_FLOWS, 'View'))
                    && <Route path={`/ivr`} element={<IvrFlow />} />}
                {(newAuthPages(NewAdminPages.PAUSE_REASONS) || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PAUSE_REASONS, 'View'))
                    && <Route path={`/pauseReasons`} element={<PauseReasons />} />}
                {(newAuthPages(NewAdminPages.SKILLS_PAGE) || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.SKILLS_PAGE, 'View'))
                    && <Route path={`/skills`} element={<Skill />} />}
                {(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View'))
                    && <Route path={`/templates`} element={<Templates />} />}
                <Route path={`/integration`} element={<Integration />} />
                {(newAuthPages(NewAdminPages.CAMPAIGN_PAGE) || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'View') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.CAMPAIGN_PAGE, 'dataUpload'))
                    && <Route path={`/campaigns`} element={<Campaigns />} />}
                {(newAuthPages(NewAdminPages.MANAGE_NUMBERS) || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.MANAGE_NUMBERS, 'View'))
                    && <Route path={`/manageNumbers`} element={<ManageNumbers />} />}
                {(newAuthPages(NewAdminPages.MAPPING) || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Full') || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MAPPING, 'View'))
                    && <Route path={`/Mappings`} element={<Mappings />} />}
                {(newAuthPages(NewAdminPages.LOCATIONS) || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Full') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.LOCATIONS, 'View'))
                    && <Route path={`/locations`} element={<Locations />} />}
                {(newAuthPages(NewAdminPages.HOLIDAYS) || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Full') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.HOLIDAYS, 'View'))
                    && <Route path={`/holidays`} element={<Holidays />} />}
                {(newAuthPages(NewAdminPages.BULK_UPDATE) || newAuthSubAdminAccess(NewAdminPages.BULK_UPDATE, 'Edit') || 
                    newAuthSubAdminAccess(NewAdminPages.BULK_UPDATE, 'Full') || newAuthSubAdminAccess(NewAdminPages.BULK_UPDATE, 'View')) &&
                    <Route path={'/bulkUpdate'} element={<BulkUpdate />} />
                }
                {checkRoles('ROLE_ADMIN') && superAdmin
                    && <Route path={`/accounts`} element={<Users />} />}
                {(newAuthPages(NewAdminPages.SUB_USERS) || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Full') || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SUB_USERS, 'View')) && localStorage.getItem('parentUser') === 'null' && !superAdmin
                    && <Route path={`/subUsers`} element={<SubUsers />} />}
                {checkRoles('ROLE_ADMIN') && superAdmin
                    && <Route path={`/plans`} element={<Plans />} />}
                {<Route path={`/userProfile`} element={<SubUserProfile />} />}
                {(newAuthPages(NewAdminPages.SIP_LOCATION) || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SIP_LOCATION, 'View'))
                    && <Route path={'/siplocation'} element={<SIPLocation />} />}
                {(newAuthPages(NewAdminPages.MUSIC_FILE) || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Full') || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.MUSIC_FILE, 'View'))
                    && < Route path={`/music`} element={<MusicFile />} />}
                {(newAuthPages(NewAdminPages.SIP_NUMBER) || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Full') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'Edit') || newAuthSubAdminAccess(NewAdminPages.SIP_NUMBER, 'View')) && checkRoles("ROLE_ADMIN")
                    && < Route path={`/sipnumber`} element={<SipNumber />} />}
                {(newAuthPages(NewAdminPages.PREPOST_PAGE) || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.PREPOST_PAGE, 'View'))
                    && < Route path={`/addCallWork`} element={<AddCallWork />} />}

                {(newAuthPages(NewAdminPages.ADMIN_SETTTINGS) || newAuthSubAdminAccess(NewAdminPages.ADMIN_SETTTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.ADMIN_SETTTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.ADMIN_SETTTINGS, 'View'))
                    && <Route path={`/AdminSettings`} element={<AdminSettings />} />}
                {(newAuthPages(NewAdminPages.AGENT_SETTINGS) || newAuthSubAdminAccess(NewAdminPages.AGENT_SETTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENT_SETTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.AGENT_SETTINGS, 'View'))
                    && < Route path={`/AgentSettings`} element={<AgentSettings />} />}
                {(newAuthPages(NewAdminPages.INTEGRATION_SETTINGS) || newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, 'Full') || newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, 'View'))
                    && <Route path={`/integrations`} element={<IntegrationsHome />} />}
                {checkRoles('ROLE_CXI') &&
				<>
                {<Route path={`/cxhubReach`} element={<CXIReachContent />} />}
                {<Route path={`/baTemplates`} element={<BATemplates/>} />}
                {<Route path={`/baSenders`} element={<SenderManagement/>} />}
                {<Route path={`/rateCards`} element={<RateCards/>} />}
                {<Route path={`/rateTrans`} element={<RateTrans/>} />}
                </>
				}
                {/* <Route path={'/didaddition'} element={<DIDAddition />} /> */}
                {/* <Route path={`/dnc`} element={<DNCRegulations />} /> */}

                {
                    (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_USER")) &&
                    <>
                        {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'dataUpload') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'View')) && <Route path={`/agent`} element={<Agent />} />}
                        {(newAuthPages(NewAdminPages.GROUPS) || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Edit') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'Full') || newAuthSubAdminAccess(NewAdminPages.GROUPS, 'View')) && <Route path={`/groups`} element={<Groups />} />}
                    </>
                }
                {checkRoles('ROLE_ADMIN') && superAdmin &&
                    <>
                        <Route path={'/widgetdetails'} element={<WidgetDetails/>}/>
                        <Route path={'/features'} element={<Features/>}/>
                    </>
                }
            </Routes >
        </div >
    )
}

export default Admin;